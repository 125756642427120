@font-face {
  font-family: myFirstFont;
  src: url(./assets/Lato/Lato-Regular.ttf);
}

* {
  font-family: "Lato", sans-serif !important;
}

#root {
  height: 100%;
  min-height: 100%;
}

html {
  overflow-x: hidden;
}

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

.App {
  width: 100%;
  height: inherit;
  min-height: 100%;
  font-family: "Lato", sans-serif !important;
  background-color: rgb(248, 248, 249);
}

button {
  border: unset;
  padding: 0;
  background: unset;
  outline: none !important;
  box-shadow: none !important;
}

.object_fit_cover {
  object-fit: cover;
}

img {
  vertical-align: unset !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-60 {
  height: 60% !important;
}

.h-40 {
  height: 40% !important;
}

.h-70 {
  height: 70% !important;
}

.h-30 {
  height: 30% !important;
}

@media screen and (max-width: 575px) {
  .h-70 {
    height: 40% !important;
  }
}

/*page transition*/
.page {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 100ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 100ms linear;
}

.transition-group {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.message-container {
  color: #9e9ea1;
  font-size: 14px;
}

.empty-message-width {
  width: 100%;
  margin: 0;
}

.sk-spinner {
  transform: scale(0.5);
}

.pointer {
  cursor: pointer;
}

.separation-line {
  border-bottom: 1px solid rgb(243, 243, 244) !important;
}

@media screen and (max-width: 899px) {
  .empty-message-width {
    width: 80%;
    margin: 0;
  }
}

@media screen and (max-width: 765px) {
  .empty-message-width {
    width: 100%;
    margin: 0;
  }
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (min-height: 320px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.full {
  height: 100%;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.center-start-jus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.center-end-jus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.center-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.pd-0 {
  padding: 0 !important;
}

.col-sm-12 {
  padding: 0 !important;
}

textarea {
  resize: none !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pdl-0 {
  padding-left: 0 !important;
}

.mg-0 {
  margin: 0 !important;
}

.cover-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

select.form-control {
  padding-right: 25px;
  background-image: url("data:image/svg+xml,\
      <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'\
           height='14px' viewBox='0 0 1200 1000' fill='rgb(51,51,51)'>\
        <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/>\
      </svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 7px) 50%;
  -moz-appearance: none;
  appearance: none;
}

.danger-color {
  color: #f9423a !important;
}
