.screen-header-container {
  width: 100%;
  z-index: 200;
}

.screen-header-items-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding-top: 18px;
  padding-bottom: 14px;
}

.chat_noti_burger {
  position: absolute;
  left: 40px;
  top: 15px;
  z-index: 1000;
}

.screen-header-name-container {
  display: flex;
}

.screen-header-name {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.8pt;
  text-transform: uppercase;
  margin-top: 3px;
  height: 19px;
}

.screen-header-event-name {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 3px;
  height: 19px;
  cursor: pointer;
}

.icon-arrow {
  margin-left: 5px;
  color: white;
}

.icon-arrow.up {
  margin-top: 6px;
}

.back-button {
  position: absolute;
  left: 19px;
  outline: none !important;
  box-shadow: none !important;
}

.icon-back {
  width: 10px !important;
  height: 18px;
  color: white;
}

.favourite-button {
  position: absolute;
  right: 15px;
  outline: none !important;
  box-shadow: none !important;
}

.close-button {
  position: absolute;
  right: 12px;
}

.icon-close {
  width: 26px !important;
  height: 27px;
  color: white;
}

.icon-close img {
  width: 100%;
}

.info-button {
  position: absolute;
  left: 21px;
}

.map-button {
  position: absolute;
  right: 19px;
  bottom: 0;
  line-height: 100%;
}
.edit-button {
  position: absolute;
  right: 19px;
  bottom: 0;
}

.edit-button-0 {
  position: absolute;
  right: 19px;
  top: 11px;
  cursor: pointer;
}

.edit-button-save {
  position: absolute;
  right: 19px;
  top: 8px;
  cursor: pointer;
}

.icon-create-post {
  height: 24px;
  color: white;
}

.icon-heart {
  width: 24px !important;
  height: 20px;
  color: white;
}

.show-qr-code-button {
  width: 45px;
  height: 47px;
  position: absolute;
  right: 21px;
  top: 10px;
}

.qr-code-icon {
  width: 100%;
}

.show-more-schedule-option-button {
  position: absolute;
  right: 20px;
}

.icon-more-vertical {
  color: white;
  width: 8px !important;
  height: 26px;
}

@media only screen and (max-width: 320px) {
  .screen-header-items-container {
    padding: 16px 0 11px 0;
  }

  .info-button {
    left: 17px;
  }

  .map-button {
    right: 16px;
  }
}

/*background_colors*/

.light-blue {
  background-image: linear-gradient(rgb(193, 214, 238), rgb(187, 207, 230));
}

.blue {
  background-image: linear-gradient(rgb(25, 61, 110), rgb(31, 68, 117));
}

.light-mint {
  background-color: rgb(69, 214, 197);
}

.purple-gradient-search-new {
  background-image: linear-gradient(rgb(22, 10, 32), rgb(27, 14, 37));
}

.purple-gradient-user-detail {
  background-image: linear-gradient(rgb(22, 10, 32), rgb(24, 13, 33));
}

.purple-gradient {
  background-image: linear-gradient(rgb(22, 10, 32), rgb(23, 11, 33));
}

.smartApp_banner-top {
  background-color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 11px 13px 11px 11px;
  position: relative;
  width: 100%;
  font-size: 14px;
}

.smartApp_banner-image {
  width: 40px;
  height: 40px;
  margin: 0 5px 0 11px;
}

.smartApp_banner_section-one {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 200px;
  -ms-flex: 1 1 200px;
  flex: 1 1 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 1px;
  padding: 0 11px 0 11px;
}

.smartApp_banner_section-two {
  text-align: right;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.smartApp_banner_text-top {
  padding: 0;
  margin: 0;
  color: #0095f6;
  font-weight: 600;
}

.smartApp_banner_text-topSupport {
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.smartApp_banner_close-top {
}

.get_btn {
  padding: 3px 7px 3px 7px;
  border: 1px solid black;
  margin-right: 10px;
}

.icon-dots-btn_v {
  background-color: transparent !important;
  border: none !important;
}
